.headers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 1em 0;
}

.content > iframe {
  width: 80%;
  height: 29em;
}
