.container {
  text-align: center;
  width: 100%;
  min-height: 100%;
}

.title-container {
  width: 40%;
}

.header {
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.header a {
  color: black;
  text-decoration: underline;
}

.header .record {
  font-size: 3em;
  font-style: italic;
}

.header img {
  width: 22em;
  height: 22em;
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1em;
  width: 1em;
  border: solid black 0.18em;
  border-radius: 100%;
  padding: 0.5em;
  color: black;
  font-size: 3em;
}
