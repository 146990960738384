.container {
  text-align: left;
  display: flex;
  gap: 1em;
  font-size: 1.5em;
  padding: 0.2em 0.5em;
  /* background-color: #333;
  border-radius: 0.3em; */
}

.medal-wrapper {
  position: relative;
  width: 1.5em;
  height: 1.35em;
  background-color: #222;
  border-radius: 0 0 70% 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medal-wrapper:before,
.medal-wrapper:after {
  position: absolute;
  margin-top: 50%;
  content: "";
  z-index: -1;
  left: 50%;
  top: 0;
  width: 50%;
  height: 53%;
  background: #222;
  border-radius: 70% 70% 0 0;
  -webkit-transform: rotate(-61.5deg);
  -moz-transform: rotate(-61.5deg);
  -ms-transform: rotate(-61.5deg);
  -o-transform: rotate(-61.5deg);
  transform: rotate(-61.5deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.medal-wrapper:after {
  background-color: #222;
  left: 0;
  -webkit-transform: rotate(61.5deg);
  -moz-transform: rotate(61.5deg);
  -ms-transform: rotate(61.5deg);
  -o-transform: rotate(61.5deg);
  transform: rotate(61.5deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.icon {
  color: #ddd;
}
