.container {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  color: white;
  background-image: linear-gradient(to right, orange, #ff6633);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.logo {
  width: 100%;
  max-height: 40%;
  display: flex;
  justify-content: center;
  gap: 8em;
  align-items: center;
}

.logo-title {
  width: 40%;
}

.grid-title {
  grid-column: 1 / span 3;
  text-align: left;
}

.recent {
  display: grid;
  max-height: 50%;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0em;
  column-gap: 5em;
}
