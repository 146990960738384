.container {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.1em;
}

.filters {
  width: 60%;
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.sort {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
}

.invert + div {
  width: 60%;
}

.filters input,
.filters select {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  font-size: 1em;
  padding: 0 1em;
  height: 3em;
  width: 100%;
}

.invert {
  cursor: pointer;
  color: #222;
  background-color: white;
  border: solid #222 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.invert:hover {
  background-color: #eee;
}

.invert:active {
  background-color: #e3e3e3;
}

.invert > i {
  font-size: 1.4em;
}

.grid {
  margin-bottom: 2em;
  max-width: 80%;
  display: grid;
  gap: 1.2em;
}

.grid a {
  font-size: 1em;
}
