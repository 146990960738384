.container {
  width: 100%;
  height: 95vh;
}

.hero {
  box-sizing: content-box;
  height: 35%;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  padding: 1em 0;
}

.hero > * {
  box-sizing: border-box;
  max-height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.profile > img {
  height: 80%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border: solid black 3px;
  border-radius: 100%;
}

.profile > h3 {
  height: 20%;
  width: 100%;
}

.stats {
  font-size: 1.5em;
  gap: 2em;
}

.stats a {
  color: #222;
}

.stats span {
  font-weight: 700;
  font-style: italic;
}
