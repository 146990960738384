.mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.container {
  position: relative;
  width: 80%;
  max-height: 80%;
  background-color: #ddd;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overscroll-behavior: none;
}

.container .close {
  font-size: 1.5em;
  margin: 0.2em;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.title {
  margin: 2em;
}
