@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ddd;
}

hr {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 6em;
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.icon-container i {
  font-size: 3em;
}

.pb-2 {
  padding: 0 0 2em 0;
}

.review {
  width: 80%;
  margin: 0 auto;
  text-align: start;
  padding-bottom: 1em;
}

.review p {
  text-align: justify;
}

.review a {
  text-decoration: underline;
  color: #ff6633;
}
.review hr {
  border-top: solid #ff6633 1px;
}

.review ul,
.review li {
  text-align: start;
}

.card {
  cursor: pointer;
  position: relative;
  max-width: 22em;
  max-height: 22em;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.1);
}

.card img {
  width: 100%;
  max-width: 22em;
  max-height: 22em;
}

.card .left-badge,
.card .right-badge {
  position: absolute;
  top: 0px;
  padding: 0px 0.3em;
  margin: 0.2em;
  border: solid black 1px;
  border-radius: 0.2em;
  color: #ddd;
  background-color: #222;
  transition: all 0.2s ease-in-out;
}

.card:hover .left-badge,
.card:hover .right-badge {
  opacity: 0;
}

.card .left-badge {
  left: 0px;
}

.card .right-badge {
  right: 0px;
}

.left,
.center {
  gap: 0.5em;
  font-size: 1em;
}

.left {
  display: flex;
  justify-content: flex-start;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-header,
.tab-header-selected {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5em;
  width: 11em;
  padding: 0 1em;
}

.tab-header-selected {
  border-bottom: solid #ff6633 1px;
}

.tab-header > *,
.tab-header-selected > * {
  transition: font-size 0.3s;
}

.tab-header > h3,
.tab-header-selected > h3 {
  font-size: 1.2em;
  text-align: center;
}

.tab-header:hover > h3,
.tab-header-selected:hover > h3 {
  font-size: 1.5em;
}

.tab-header > i,
.tab-header-selected > i {
  font-size: 4em;
}

.tab-header:hover > i,
.tab-header-selected:hover > i {
  font-size: 5em;
}

.spacing {
  height: 1em;
}

.footer {
  height: 2em;
  width: 100%;
  text-align: center;
  color: #222;
}

#me {
  text-decoration: underline;
}
