.container {
  height: 5em;
  width: 100%;
  color: white;
  background-image: linear-gradient(to right, orange, #ff6633);
  display: flex;
  gap: 8em;
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  color: inherit;
}
